$(function () {

    const scrollAnimation = (screenSize) => {
        let Y = window.pageYOffset;

        $('.greeting_logo').css({
            top: '50%',
            left: '50%',
            transform: `translate(-47.5%, -${-50 + Y/3 }%)`,
            opacity: 1,
            animation: 'unset'
        })

        if (Y < 1028) {
            let h = (-$('.camera.strap').height() + Y / 3);
            $('.camera.strap').css({
                top: h < -30 ? h : -30,
            })
        } else if (Y >= 1028 && Y < 2056) {
            $('.camera.strap').css({
                top: $('.camera.strap').height() - Y / 2,
            })
        } else if (Y >= 2056) {
            $('.camera.strap').removeAttr('style')
        }

        if (Y < 800) {
            let h = (-$('.flame.top').height() / 2) + Y / 3;
            console.log(h)
            $('.flame.top').css({
                top: h < -30 ? h : -30,
            })
        } else if (Y >= 800 && Y < 1400) {
            return;
        } else if (Y >= 1400 && Y < 2200) {
            let h = $('.flame.top').height() - Y / 1.75;
            $('.flame.top').css({
                top: h,
            })
        } else if (Y >= 2200) {
            let h = $('.flame.top').height() - Y / 1.75;
            $('.flame.top').css({
                top: h,
            })
        }

        if (Y < 1400) {
            $('.camera.nostrap').css({
                top: '-100%',
            })
        } else if (Y >= 1400) {
            $('.camera.nostrap').css({
                top: -$('.camera.nostrap').height() + Y / 2.75,
                transform: `rotate(${Y/5}deg)`
            })
        }

        if (Y > 1600) {
            $('.book').css({
                left: `${40 - Y/95}%`,
                transform: `rotateX(${Y/5}deg) rotateZ(${Y/8}deg)`
            })
        }

        if (Y <= 2300) {
            $('.flame.center').css({
                top: $('.flame.center').height() - Y / 1.75,
            })
        } else if (Y > 2300 && Y < 2800) {
            $('.flame.center').css({
                top: -190,
            })
        } else if (Y >= 2800) {
            $('.flame.center').css({
                top: $('.flame.center').height() - Y / 1.85,
            })
        }

        if (Y > 2800) {
            $('.pencil').css({
                right: `${Y/125}%`,
                transform: `rotate(-${Y/80}deg)`
            })
        }

        if (Y > 2900) {
            $('.drone').css({
                left: `${Y/160}%`,
                transform: `rotate(${Y/110}deg) scale(${Y/2500})`
            })
        }

    }

    $(window).resize(function () {
        window.innerWidth
    })

    const initRelax = () => {
        if ($('[data-rellax-speed]').length > 0) {
            var rellax = new Rellax('[data-rellax-speed]');
        }
    }

    const initPerloader = () => {
        if (window.location.pathname === '/') {
            anime.timeline({
                    loop: false
                })
                .add({
                    targets: '.preloader-img',
                    top: [0, '50%'],
                    left: '50%',
                    translateY: ['-100%', '-43%'],
                    translateX: '-50%',
                    scale: [0, 1],
                    easing: 'spring(1, 80, 10, 0)'
                })
                .add({
                    targets: '.preloader-img',
                    scale: [1, .6],
                    translateX: ['-50%', '-100%'],
                    easing: 'easeInOutQuad'
                })

            anime({
                targets: '.preloader-text',
                opacity: [0, 1],
                translateX: ['-30%', '-15%'],
                easing: 'easeInOutQuad',
                delay: 1900,
            })

            anime({
                targets: '.preloader',
                opacity: [1, 0],
                easing: 'easeInOutQuad',
                delay: 4000
            })

            setTimeout(() => {
                $('main').fadeIn()
                $('body').removeClass('preloader-show');
                $('.preloader').hide();
            }, 5000);

            anime({
                targets: '.greeting_logo',
                translateY: ['-65%', '-50%'],
                translateX: ['-47.5%', '-47.5%'],
                opacity: [0, 1],
                easing: 'easeInOutQuad',
                duration: 500,
                delay: 5300
            })

        }
    }

    const animatePage = () => {
        anime({
            targets: '.page-title',
            translateY: [-20, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            delay: 1000
        })

        anime({
            targets: '.logo-complete',
            translateX: [-30, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            delay: 1000
        })

        anime({
            targets: '.card',
            translateY: [30, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            delay: 500
        })
    }

    initPerloader();
    initRelax();
    animatePage();
    $(window).scroll(() => scrollAnimation());

})
