$(function () {

    const tiltOptions = {
        maxTilt: 30,
        perspective: 1000,
        easing: "cubic-bezier(.03,.98,.52,.99)",
        scale: 1,
        speed: 300,
        transition: true,
        reset: true,
        glare: false,
        maxGlare: 1
    }

    $(document).mousemove(function (e) {
        let _w = window.innerWidth / 2;
        let _h = window.innerHeight / 2;
        let _mouseX = e.clientX;
        let _mouseY = e.clientY;
        $('.bg_1').css({
            'transform': `translate(-${50 - (_mouseX - _w) * 0.01}%, -${50 - (_mouseY - _h) * 0.01}%)`
        })
        $('.bg_2').css({
            'transform': `translate(-${50 - (_mouseX - _w) * 0.0035}%, -${50 - (_mouseY - _h) * 0.0035}%)`
        })
    })

    const $navigation = document.querySelector(".navigation");
    let isMenuOpen = false;
    $navigation.addEventListener("click", () => {
        if (!isMenuOpen) {
            $($navigation).find('.menu-btn').addClass("open");
        } else {
            $($navigation).find('.menu-btn').removeClass("open");
        }

        isMenuOpen = !isMenuOpen;
    });

    $('[data-anim="tilt"]').tilt(tiltOptions);

    $('.navigation').click(function () {
        $('body').toggleClass('menu-show');
        if ($('body').hasClass('menu-show')) {
            const animated = anime.timeline({
                loop: false
            }).add({
                targets: '.navigation__menu .navigation__item a',
                translateY: [20, 0],
                opacity: .8,
                easing: 'easeOutQuart',
                delay: anime.stagger(100, {
                    start: 600
                })
            })
        } else {
            $('.navigation__menu .navigation__item a').attr('style', '')
        }
    })

    $('.navigation__item a').click(function () {
        $('.navigation__item a').removeClass('active');
        $(this).addClass('active');
    })

    $(document).ready(function () {
        $(".owl-carousel").owlCarousel({
            loop: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            },
            margin: 30,
            smartSpeed: 450,
            autoplay: true,
            autoplayTimeout: 2500,
        });
    })

    const video = document.getElementById("video"),
        floatBg = document.querySelectorAll('[data-usage="floating-bg"]'),
        content = document.querySelectorAll('.content');

    setTimeout(function () {
        video.play();
    }, 5500);

    const toDataUrl = (uri) => fetch(uri)
        .then((res) => res.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


    floatBg.forEach((el) => {
        const url = el.getAttribute('data-src');
        toDataUrl(url).then(dataUrl => {
            el.src = dataUrl;
        })
    })

    content.forEach((el) => {
        const url = el.getAttribute('data-src');
        toDataUrl(url).then(dataUrl => {
            el.src = dataUrl;
        })
    })

});
